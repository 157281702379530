<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3 class="float-left">Nuevo Producto</h3>
      </CCardHeader>

      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="contact-form" method="post" v-on:submit.prevent="handleSubmit(registrar)">
           <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Producto:
                  </label>
                  <validation-provider
                    name="Producto"
                    :rules="{ required: true }"
                    :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                    v-slot="validationContext"
                  >
                    <input
                      type="text"
                      v-model="registro.producto"
                      class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Descripción corta:
                  </label>
                  <validation-provider
                    name="Descripción corta"
                    :rules="{ required: true }"
                    :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                    v-slot="validationContext"
                  >
                    <input
                      type="text"
                      v-model="registro.descripcion_corta"
                      class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Descripción:
                  </label>
                  <validation-provider
                    name="Descripción"
                    :rules="{ required: true }"
                    :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                    v-slot="validationContext"
                  >
                    <input
                      type="text"
                      v-model="registro.descripcion"
                      class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
               <CCol sm="12">
                <div class="form-group">
                  <label>
                    Estado producto:
                  </label>
                  <validation-provider
                    name="Estado producto"
                    :rules="{ required: true }"
                    :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                    v-slot="validationContext"
                  >
                    <selectEstadoProducto @ActualizandoId="setEstadoProducto" />
                    <input type="hidden" v-model="registro.eco_producto_condicion_id" />

                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
          </CRow>
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Marca:
                  </label>
                  <validation-provider
                      name="Marca"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <selectMarca @ActualizandoId="setMarca" />
                    <input type="hidden" v-model="registro.eco_marca_id" />

                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
               <CCol sm="12">
                <div class="form-group">
                  <label>
                    Idioma:
                  </label>
                  <validation-provider
                    name="Idioma"
                    :rules="{ required: true }"
                    :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                    v-slot="validationContext"
                  >
                    <selectIdioma @ActualizandoId="setIdioma" />
                    <input type="hidden" v-model="registro.eco_idioma_id" />

                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
          </CRow>
            <CRow>
              <CCol sm="12">
                <div class="form-group row">
                  <label class="col-md-4 form-control-label">Imagen:</label>
                  <div class="col-md-12" v-if="!registro.imagen_subida">
                    <img style="max-height: 100px;" :src="registro.imagen" alt="" />
                    <input
                      name="inputFile"
                      @change="handleImage"
                      class="form-control"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                    />
                  </div>
                  <div class="col-md-9" v-if="registro.imagen_subida">
                    <img
                      style="max-height: 100px; width: 100px"
                      :src="'/storage/eco/small_' + registro.imagen"
                      alt=""
                    />&nbsp;
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="borrarImagen(registro.id, registro.imagen)"
                    >
                      <i class="cil-trash"></i>
                      Borrar
                    </button>
                  </div>
                </div>
              </CCol>
            </CRow>

            <div class="form-group">
              <button type="submit" class="btn btn-primary">
                <span class="cil-save btn-icon mr-2"></span>
                Guardar
              </button>

              <router-link to="/ecoProductos/List">
                <CButton class="ml-2" color="secondary">
                  <span class="cil-x-circle btn-icon mr-2"></span>
                  Cancelar
                </CButton>
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'
import selectEstadoProducto from '@/components/SelectEstadoProducto.vue'
import selectMarca from "@/components/SelectMarca.vue"
export default {
  components: {
    selectIdioma,
    selectEstadoProducto,
    selectMarca
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      registro: {}
    };
  },

  methods: {
    async registrar() {
      try {
        let url ="eco-productos";
        this.registro.publicado = 1;
        let response = await httpService.post(url, this.registro);
        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        this.$router.back()
      } catch (error) {
        console.log(error);
      }
    },

    setIdioma(value) {
      this.registro.eco_idioma_id = value
    },

    setEstadoProducto(value) {
      this.registro.eco_producto_condicion_id = value
    },

    setMarca(value) {
      this.registro.eco_marca_id = value
    },

    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      if (selectedImage.type === 'image/jpeg' || selectedImage.type === 'image/jpg' || selectedImage.type === 'image/png') {
        this.createBase64Image(selectedImage);
      } else {
        this.registro.imagen = 'invalid';
        Vue.$toast.success('Tipo de imagen no válida. Por favor, seleccione otro archivo.', {
          type: 'error',
          position: 'top-right'
        });
      }
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.registro.imagen = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
  }
};
</script>