var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('h3',{staticClass:"float-left"},[_vm._v("Nuevo Producto")])]),_c('CCardBody',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact-form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registrar)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Producto: ")]),_c('validation-provider',{attrs:{"name":"Producto","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.producto),expression:"registro.producto"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.producto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "producto", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Descripción corta: ")]),_c('validation-provider',{attrs:{"name":"Descripción corta","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.descripcion_corta),expression:"registro.descripcion_corta"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.descripcion_corta)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "descripcion_corta", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Descripción: ")]),_c('validation-provider',{attrs:{"name":"Descripción","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.descripcion),expression:"registro.descripcion"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.descripcion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "descripcion", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Estado producto: ")]),_c('validation-provider',{attrs:{"name":"Estado producto","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectEstadoProducto',{on:{"ActualizandoId":_vm.setEstadoProducto}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_producto_condicion_id),expression:"registro.eco_producto_condicion_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_producto_condicion_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_producto_condicion_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Marca: ")]),_c('validation-provider',{attrs:{"name":"Marca","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectMarca',{on:{"ActualizandoId":_vm.setMarca}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_marca_id),expression:"registro.eco_marca_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_marca_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_marca_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Idioma: ")]),_c('validation-provider',{attrs:{"name":"Idioma","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectIdioma',{on:{"ActualizandoId":_vm.setIdioma}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_idioma_id),expression:"registro.eco_idioma_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_idioma_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_idioma_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 form-control-label"},[_vm._v("Imagen:")]),(!_vm.registro.imagen_subida)?_c('div',{staticClass:"col-md-12"},[_c('img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.registro.imagen,"alt":""}}),_c('input',{staticClass:"form-control",attrs:{"name":"inputFile","type":"file","accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.handleImage}})]):_vm._e(),(_vm.registro.imagen_subida)?_c('div',{staticClass:"col-md-9"},[_c('img',{staticStyle:{"max-height":"100px","width":"100px"},attrs:{"src":'/storage/eco/small_' + _vm.registro.imagen,"alt":""}}),_vm._v("  "),_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.borrarImagen(_vm.registro.id, _vm.registro.imagen)}}},[_c('i',{staticClass:"cil-trash"}),_vm._v(" Borrar ")])]):_vm._e()])])],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('span',{staticClass:"cil-save btn-icon mr-2"}),_vm._v(" Guardar ")]),_c('router-link',{attrs:{"to":"/ecoProductos/List"}},[_c('CButton',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"cil-x-circle btn-icon mr-2"}),_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }